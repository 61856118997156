import '../styles/global.scss';
import '../styles/pages/payment.scss';
import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {addDays, format} from 'date-fns';
import {HeadFC} from "gatsby";
import {Link} from 'gatsby';
import {
    LoadingGif,
    DotBar,
    ArrowNext,
    iphoneFinish2,
    finishAppStore,
    finishGPlay,
    visaPay,
    masterCardPay,
    GrommetPay,
    StripeImage2,
    ApplePay,
    GPay,
} from "../images";
import {useMemo, useRef, useState, useEffect} from "react";
import CardFormLight from "../components/CardFormLight";
import {StripeError} from "@stripe/stripe-js/types/stripe-js/stripe";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import useAnalytics from "../hooks/useAnalytics";
import QType16 from "../components/QuestionTypes/type16";

const QuestionProps = {
    text: `Money shouldn't stay in the way of reaching your goals. So choose an amount than you think is reasonable to try us out`,
    descr: `it costs us 7,49$ to cover our expenses for the trial, but please choose an amount you're comfortable with`
}

const PaymentPage = () => {
    const {initAnalytics, saveEmail, saveSubType, saveTrialPrice} = useAnalytics();

    useEffect(() => {
        initAnalytics()
    }, []);

    const [eventStatus, setEventStatus] = useState(false);

    useEffect(() => {
        if (!eventStatus) {
            // fbq("track", "Lead");
            setEventStatus(true);
        }
    }, [eventStatus]);

    const cardFormRef = useRef();
    const [currentStep, setCurrentStep] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [isCardValid, setCardValid] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState<string>('1.0')
    //pk_test_51KoA95Gxzah9dg4Qi3TtrnursgyxrW6YDz0DV1wArKD39N3KXq43XxakjTqCTl6PJH0wmkdbVsTQrSQ8bNQQdYhC00dCv7cvzg
    const stripe = loadStripe('pk_live_51KoA95Gxzah9dg4QawTHGGHiG7NHrUucf3QYIWD69yXAuPARtiheAKsB5Ht3EUmTx5WgxPbrgcX52djjCRq6n6V8000ZFScU5d');

    const onCardValid = (status: boolean) => {
        setCardValid(status);
    }

    const onPayClick = async () => {
        if (currentStep === 0) {
            if (email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                saveEmail(email);
                setCurrentStep(currentStep + 1);
                // fbq("track", "Subscribe");
            } else {
                toast('Invalid e-mail address', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
            }

        }

        if (currentStep === 1) {
            saveTrialPrice(email, paymentMethod);
            setCurrentStep(currentStep + 1)
        }

        if (currentStep === 2) {
            setLoading(true);
            const card = await (cardFormRef.current as any).onPayClick();
            setLoading(false);

            if (card.error) {
                console.log('Error', card.error);

                toast(card.error.message, {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
                return;
            }

            makePayment(card.setupIntent.payment_method)
        }
    }

    const makePayment = (cardToken: string, ev?: any) => {
        if (cardToken && email && email.length) {
            setLoading(true);
            axios.post(`https://aeer.app/api/v1/payment/stripe`, {
                cardToken,
                email
            }).then((res) => {
                saveSubType(email)
                // fbq("track", "Purchase");
                if (ev) {
                    ev.complete('success');
                }
                setCurrentStep(currentStep + 1);
            }).catch(() => {
                ev.complete('fail');
                toast('Payment error, please check your credit card data', {
                    autoClose: 5000,
                    type: 'error',
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                })
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleTokenCreate = (token: string, ev: any) => {
        makePayment(token, ev);
    }

    const handleCardError = (e: StripeError) => {
        console.log('e', e)
    }

    const endDate = useMemo(() => {
        return format(addDays(new Date(), 7), 'dd MMM yyyy')
    }, [])

    const renderCardForm = () => {
        return (
            <div className='question active'>
                <div className="paypage">
                    <div className="paypagePrice">
                        <p>Special Price</p>
                        <div className="paypagePriceRow">
                            <b>${paymentMethod}</b>
                            <span>USD</span>
                        </div>
                    </div>
                    <div className="paypageWidget">
                        <Elements stripe={stripe}>
                            <CardFormLight
                                trialPrice={Number.parseFloat(paymentMethod)}
                                onGetCardToken={handleTokenCreate}
                                onCardError={handleCardError}
                                ref={cardFormRef}
                                onValidationChange={onCardValid}/>
                        </Elements>
                    </div>
                    <div className="paypageStripe">
                        <img src={StripeImage2} alt=""/>
                        <span>Your information is 100% secure. <br/> The payment is processed by Stripe</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderSuccess = () => {
        return (
            <>
                <div className='question active withFooter'>
                    <p className="questionName big2 mb0">Start transforming your life <br/> with AEER Platform</p>
                    <div className="quesFinish">
                        <img src={iphoneFinish2} alt="" className="quesFinishPhone2"/>
                        <div className="quesRemark">
                            <p>Your free trial starts today and ends on {endDate}.</p>
                        </div>
                        <div className="quesFinishDownload">
                            <a href="https://apps.apple.com/us/app/aeer-education-productivity/id1630377140"
                               target="_blank">
                                <img src={finishAppStore} alt=""/>
                            </a>
                            <a href="#">
                                <img src={finishGPlay} alt=""/>
                            </a>
                        </div>
                        <p>Your subscription will auto-renew every month after that. You can cancel it at any time up to
                            24 hours before the next renewal</p>
                        <p>After chargeless 7-day trial, the subscription cost is just $4.99 per month. Cancel your
                            subscription at any time from personal account</p>
                    </div>
                    <div className="questionFooter">
                        <p>© AEER PLATFORM INC <br/> 8 THE GREEN STE A DOVER, DE 19901</p>
                        <Link to="/terms">Terms of User</Link>
                        <Link to="/privacy">Privacy policy</Link>
                        <Link to="/cancel">Unsubscribe</Link>
                    </div>
                </div>
            </>
        )
    }

    const renderEmailForm = () => {
        return (
            <>
                <div className='question active'>
                    <div className="quesEmail">
                        <p className={`questionName medium mbs wow delay ${isLoading ? '' : 'animate'}`}>
                            Sign up with your email!
                        </p>
                        <p className={`questionDescr wow delay01 ${isLoading ? '' : 'animate'}`}>A few more steps and
                            you're done! We hate <br/> paperwork too</p>
                        <div className={`quesEmailInput wow delay11 ${isLoading ? '' : 'animate'}`}>
                            <label htmlFor="email">Email</label>
                            <input id="email" onChange={(e) => setEmail(e.target.value)} type="email"
                                   placeholder="Enter please your email"/>
                            <p className="quesEmailRemark">Your information is 100% secure. AEER Platform does not share
                                your personal information with third parties</p>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const onChangePrice = (price: string) => {
        setPaymentMethod(price);
    }

    const renderQuestionForm = () => {
        return <QType16 quesKey={QuestionProps} changePrice={onChangePrice}/>
    }

    const renderStep = useMemo(() => {
        switch (currentStep) {
            case 0: {
                return renderEmailForm()
            }
            case 1: {
                return renderQuestionForm();
            }
            case 2: {
                return renderCardForm();
            }
            case 3: {
                return renderSuccess();
            }
            default: {
                return renderCardForm();
            }
        }
    }, [currentStep]);

    const progressVal = () => {
        if (currentStep === 0) {
            return 95;
        }
        if (currentStep === 1) {
            return 97;
        }
        if (currentStep === 2) {
            return 100;
        }
    }


    return (
        <>
            <style>
                {
                    `body {
                        background: #F8F8FD;
                    }`
                }
            </style>
            <div className="questionSection">
                <div className="questionWrap">
                    <div className="questionHeading">
                        <p>A few more steps</p>
                    </div>
                    <div className="questionProgress">
                        <div className={`questionProgressLine ${(progressVal() === 100) ? 'forth' : 'third'}`}>
                            <span>
                                <img src={DotBar} alt=""/>
                            </span>
                            <span>
                                <img src={DotBar} alt=""/>
                            </span>
                            <span>
                                <img src={DotBar} alt=""/>
                            </span>
                            <span>
                                <img src={DotBar} alt=""/>
                            </span>
                            <div style={{width: progressVal() + '%'}}></div>
                        </div>
                    </div>
                    {renderStep}

                    {currentStep === 0 && (
                        <button id="selectEmailBtn" className="questionBtn" onClick={onPayClick} disabled={isLoading}>
                            <span id="selectEmailBtnSpan">Get a 7-day trial</span>
                            <img id="selectEmailBtnIcon" src={ArrowNext} alt=""/>
                        </button>
                    )}

                    {currentStep === 1 && (
                        <button id="selectPriceBtn" className="questionBtn" onClick={onPayClick}
                                disabled={!paymentMethod}>
                            <span id="selectPriceBtnSpan">Pay ${paymentMethod} USD</span>
                            <img id="selectPriceBtnIcon" src={ArrowNext} alt=""/>
                        </button>
                    )}

                    {currentStep === 2 && (
                        <button id="selectPaymentBtn" className="questionBtn" onClick={onPayClick}
                                disabled={!isCardValid || isLoading}>
                            <span id="selectPaymentBtnSpan">Pay ${paymentMethod} USD</span>
                            <img id="selectPaymentBtnIcon" src={ArrowNext} alt=""/>
                        </button>
                    )}

                </div>
            </div>
            {isLoading && (
                <div className="loaderWrap">
                    <div className="loader">
                        <img width={44} src={LoadingGif} alt=""/>
                    </div>
                </div>
            )}
            <ToastContainer/>
        </>
    )
}

export default PaymentPage;

export const Head: HeadFC = () => (
    <>
        <title>Checkout - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);

